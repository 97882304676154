import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { AdminCompanyService } from '@/admin/data/AdminCompanyService';
import { CompanyOptions } from '@/common/models/companies/CompanyOptions';
import { Guid } from '@/common/models/Guid';
import { isNil } from '@/common/utils/TypeFunctions';

interface Options {
  enabled?: boolean;
  onError?: (error: any) => void;
}

export const useCompanyOptionsData = (companyId?: Guid, options?: Options) => {
  const cacheKey = useMemo(
    () => AdminCompanyService.cacheKeys.companyOptions(companyId),
    [companyId]
  );
  const { data, isLoading } = useQuery(
    cacheKey,
    () => AdminCompanyService.getOptionsAsync(companyId!),
    {
      enabled:
        !!companyId && (isNil(options?.enabled) ? true : options!.enabled),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (e) => {
        console.error(e);
        options?.onError?.(e);
      }
    }
  );

  return useMemo(() => {
    return [data || new CompanyOptions(), isLoading];
  }, [data, isLoading]) as [CompanyOptions, boolean];
};
